import { onPageReady } from '../../core/js/on-page-ready.js';

onPageReady().then(async () => {
  const { hydrateReactRootComponent } = await import(
    '../../core/js/hydrate-react.js'
  );
  const { SectionPage } = await import(
    '../../../src/aftenposten/views-react/layouts/section/SectionPageDesktop.js'
  );

  await hydrateReactRootComponent(SectionPage);
});
